import React from "react"
import { Link } from "gatsby"

import { BasicPageContainer } from "../../global"

const TermsOfUse = () => (
  <BasicPageContainer>
    <h1>Website Terms of Use</h1>
    <h2>Overview</h2>
    <p>
      Welcome to the Dismissly website and application (the “Site”). The Site is
      a simple solution to allow schools to accomplish dismissal after school
      with a minimum of hassle. The following, along with the{" "}
      <Link to="/legal/terms-of-license">Terms of License</Link> and{" "}
      <Link to="/legal/privacy-policy">Privacy Policy</Link> are terms of a
      legal agreement (collectively, the “Terms”) between you and the owner of
      the Site, Droplet, LLC. By accessing, browsing, or using the Site, you
      acknowledge that you have read, understood, and agree to be bound by these
      terms and to comply with all applicable laws and regulations applicable to you,
      including export and re-export control laws and regulations. If you are accessing,
      browsing, or using the Site in your capacity as an employee of or on
      behalf of an entity or organization, you agree to be bound by the Terms on
      behalf of that entity or organization. If you do not agree to these terms,
      please do not use the Site.
    </p>
    <p>
      The material provided on the Site is protected by law, including, but not
      limited to, United States Copyright law and international treaties.
      Droplet makes no representations as to the appropriateness of the
      materials for access from locations outside the United States. Access to
      these materials from territories where their contents are illegal is
      prohibited; if you choose to access these materials from such a territory,
      you are responsible for compliance with local laws and regulations.
    </p>
    <p>
      Droplet may at any time revise these Terms of Use, the Terms, and any
      other information contained in the Site by updating this posting or other
      Terms postings. If Droplet makes such revisions, you will be notified via
      a notation that the Terms of Use or other Terms are new next to the link
      to the relevant Terms. For major revisions as determined by Droplet in its
      sole discretion, if Droplet has your email address on file, Droplet will
      send you an email and notify you at least thirty (30) days in advance of
      the update. A notice of the last date of revision will always appear at
      the bottom of these Terms of Use. Your continued use of the Site
      constitutes your consent to any updates to the Terms.
    </p>
    <h2>Permissible Use</h2>
    <p>
      The Site and all content on the Site may not be copied, reproduced,
      republished, uploaded, posted, transmitted, distributed, mirrored, framed,
      or otherwise used or displayed, or used for the creation of derivative
      works, without Droplet’s prior written consent, except that (a) you may
      copy, reproduce, republish, upload, post, transmit, distribute, or
      otherwise use and display your own data (“Your Data”) that you have placed
      on the Site, and (b) Droplet grants you a non-exclusive, non-transferable,
      limited and revocable permission to access and display the Web pages
      within the Site, for your personal, non-commercial (unless you have a
      business relationship with Droplet) use of the Site. Droplet further
      grants you a limited, nonexclusive and revocable right to create a
      hyperlink to the Site so long as the link does not portray Droplet, its
      affiliates, or their respective products or services in a false,
      misleading, derogatory, or otherwise offensive manner. These permissions
      are conditioned on your not modifying the content displayed on this site,
      your keeping intact all copyright, trademark, and other proprietary
      notices, and your acceptance of any terms, conditions, and notices
      accompanying the content or otherwise set forth on the Site.
    </p>
    <p>
      You may not use the Site in a manner that violates any local, state,
      national, foreign or international statute. You may not interfere with the
      functioning of the Site or with other users’ use of the Site in any way,
      including but not limited to modification of the Site; attempting to gain
      access to unauthorized portions of the website; enabling high volume,
      automated, electronic processes that apply to the Site or its systems, the
      content of the Site or any portion or derivative thereof; or attempting to
      gain access to other accounts, computer systems or networks connected to
      the Site, whether through hacking, password mining, or any other means.
      You may not use the Droplet name, trademarks, services marks or logos in
      any meta tags or any other “hidden text”; use data mining, robots,
      spiders, crawlers, scrapers or similar automated or non-automated data
      gathering or extraction methods; or compile, repackage, disseminate or
      otherwise use data extracted from the Site.
    </p>
    <p>
      Your failure to comply with the terms, conditions, and notices on this
      site will result in automatic termination of any rights granted to you,
      without prior notice, and you must immediately destroy all copies of
      downloaded materials in your possession or control. Except for the limited
      permission in the preceding paragraph, Droplet does not grant you any
      express or implied rights or licenses under any patents, trademarks,
      copyrights, or other proprietary or intellectual property rights.
    </p>
    <h2>Your Account</h2>
    <p>
      If your entity or organization has purchased a license, you may create or
      be assigned an account for the Site. You may not share an account or your
      password with other users. You are responsible for the security of your
      password and for all activity that occurs on your login, including
      activity that occurs after your failure to log out after using the Site.
      In the event that your password becomes compromised, you are responsible
      for immediately changing your password upon discovery of the compromise.
      Droplet is not responsible for any unauthorized access to your account or
      data that arises from your actions or omissions. If you believe your
      account has been compromised and are unable to access it, please contact
      Droplet at support@dismissly.com.
    </p>
    <p>
      Droplet reserves the right to terminate your account or your access to the
      Site at any time for any reason in its sole discretion. You agree that
      Droplet shall have no liability to you arising out of or relating to such
      termination.
    </p>
    <h2>Children's Information</h2>
    <p>
      The Site is not meant for use by minors. If you are a legal minor in your
      jurisdiction, please do not use the Site, or access the Site only under
      the supervision of a parent or legal guardian. Furthermore, the Site does
      not knowingly solicit or collect information from children under the age
      of 13. Should Droplet learn that it has inadvertently collected
      information from a child under the age of 13, it will delete that
      information as soon as possible. The Site collects only directory
      information about children under the age of 13, and collects that
      information only from adults with supervisory authority. See our{" "}
      <Link to="/legal/privacy-policy">Privacy Policy</Link> for further
      information.
    </p>
    <h2>Student Data</h2>
    <p>
      Droplet does not wish to have access to any student data that is
      designated as an Education Record under the Family Educational Rights and
      Privacy Act and its implementing regulations (“FERPA”). The Site is
      deliberately designed to minimize the amount of personally identifiable
      information collected. If you have purchased a{" "}
      <Link to="/legal/terms-of-license">license</Link>, you should provide{" "}
      <em>only</em> information that has been properly designated Directory
      Information under FERPA.{" "}
      <strong>
        It is a violation of the Terms to provide any information about a
        student to Droplet, either through the Site or the Services or
        otherwise, that is not properly designated Directory Information under
        FERPA.
      </strong>{" "}
      Such violations are subject to the Indemnification terms below.
    </p>
    <h2>Disclaimers Regarding Content</h2>
    <p>
      Information on the Site is not promised or guaranteed to be correct,
      current, or complete, and the Site may contain inaccuracies or
      typographical errors. Droplet assumes no responsibility (and expressly
      disclaims responsibility) for updating the Site to keep information
      current or to ensure the accuracy or completeness of any posted
      information. If you upload data to the Site, you are responsible for its
      correctness, currency, accuracy, and completeness. If you believe any of
      Droplet’s information on the Site is inaccurate or incomplete, please let
      Droplet know by emailing support@dismissly.com.
    </p>
    <p>
      Droplet provides no assurances that any reported problems will be
      resolved, even if Droplet elects to provide information with the goal of
      addressing a problem.
    </p>
    <h2 id="confidential">Confidential Information and Submissions</h2>
    <p>
      For information about how the Site collects and uses personally
      identifiable information, please refer to our{" "}
      <Link to="/legal/privacy-policy">Privacy Policy</Link>. This section deals
      with confidential and proprietary information such as intellectual
      property, ideas, and trade secrets that belong to you (“Confidential
      Information”). The Site does not collect Confidential Information from
      you, except to the extent that you submit it via email or other means.
      Droplet does not want to receive Confidential Information from you through
      the Site, e-mail, or other means. Therefore, any information or material
      sent to Droplet will be deemed NOT to be confidential. By sending Droplet
      any information or material (a “Submission”), you grant Droplet an
      unrestricted, irrevocable and fully paid-up license to use, copy,
      reproduce, publish, upload, post, transmit, distribute, publicly display,
      perform, modify, create derivative works from, and otherwise freely use,
      that Submission. You also agree that Droplet is free to use any ideas,
      concepts, know-how, or techniques in any Submission that you send us for
      any purpose, without compensation of any sort. However, we will not
      release your name or otherwise publicize the fact that you submitted a
      Submission to us unless: (a) we obtain your permission to use your name;
      or (b) we first notify you that the Submission you submit to a particular
      part of the Site will be published or otherwise used with your name on it;
      or (c) we are required to do so by law.
    </p>
    <h2>Outside Websites</h2>
    <p>
      The Site may provide links or references to other websites and resources.
      Droplet does not own or control these third-party websites, and these
      Terms of Use do not apply to them. Droplet makes no representations,
      warranties, or other commitments whatsoever about any third-party websites
      resources that may be referenced, accessible from, or linked to the Site.
      A link to a third-party website does not mean that Droplet endorses the
      content or use of such website or its owner. In addition, Droplet is not a
      party to or responsible for any transactions you may enter into with third
      parties, even if you learn of such parties (or use a link to such parties)
      from the Site. Accordingly, you acknowledge and agree that Droplet is not
      responsible for the availability of such external sites or resources, and
      is not responsible or liable for any content, services, products, or other
      materials on or available from those sites or resources.
    </p>
    <p>
      When you access a third party website, even one that may contain the
      Droplet name or logo, please understand that it is independent from
      Droplet, and that Droplet does not control the content on that website. It
      is up to you to take precautions to protect yourself from viruses, worms,
      trojan horses, and other potentially destructive programs, and to protect
      your information as you deem appropriate.
    </p>
    <h2>Intellectual Property</h2>
    <p>
      Except for Your Data submitted by you, the collections and information featured on the Site are produced,
      distributed and owned by Droplet. The images and information published on
      this site are the intellectual property of Droplet. The software on the
      Site belongs to Droplet and its licensors. You acknowledge that this
      content is protected by copyrights, trademarks, trade secrets, or other
      proprietary rights, and that these rights are valid and protected in all
      forms, media and technologies existing now or hereinafter developed.
      Droplet or its licensors own a copyright in the selection, coordination,
      arrangement, and enhancement of such intellectual property. You may not
      modify, remove, delete, augment, add to, publish, transmit, participate in
      the transfer or sale of, create derivative works from, or in any way
      exploit any Droplet intellectual property, in whole or in part. To the
      extent that portions of the Site use open source software, you are
      permitted to use the open source software according to its license terms.
    </p>
    <p>
      The Droplet logo and Dismissly logo and the Droplet and Dismissly names
      are trademarks of Droplet. These, along with any other trademarks, service
      marks and logos used and displayed on the Site are trademarks of Droplet.
      Nothing on the Site should be construed as granting, by implication,
      estoppel or otherwise, any license or right to use any trademark displayed
      on the Site, without the prior written permission of Droplet. Droplet
      aggressively enforces its intellectual property rights to the fullest
      extent of the law. Fair use of Droplet’s trademarks requires proper
      acknowledgment. Other product and company names mentioned in the Site, if
      any, may be the trademarks of their respective owners.
    </p>
    <p>All rights reserved.</p>
    <h2>Indemnification</h2>
    <p>
      You agree to indemnify and hold harmless Droplet, its subsidiaries, and
      affiliates, and their officers and employees, from any damages, claims,
      liabilities, and costs, including reasonable attorney's fees, or losses of
      any kind or nature whatsoever which may in any way arise from your use or
      misuse of the Site, your violation of any law or regulation, your willful
      misconduct, any other party’s access to the Site using your account
      credentials, or any breach or alleged breach of the Terms by you. Droplet
      shall retain control over the defense of, and any resolution or settlement
      relating to, such loss. You will cooperate with Droplet and provide
      reasonable assistance in defending any such claim.
    </p>
    <h2>Disclaimer of Warranty</h2>
    <p>
      USE OF THIS SITE IS AT YOUR SOLE RISK. ALL MATERIALS, INFORMATION,
      SERVICES AND PRODUCTS ARE PROVIDED “AS IS,” WITH NO WARRANTIES OR
      GUARANTEES WHATSOEVER. DROPLET EXPRESSLY DISCLAIMS TO THE FULLEST EXTENT
      PERMITTED BY LAW ALL EXPRESS, IMPLIED, STATUTORY, AND OTHER WARRANTIES,
      GUARANTEES, OR REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, THE
      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
      NON-INFRINGEMENT OF PROPRIETARY AND INTELLECTUAL PROPERTY RIGHTS. WITHOUT
      LIMITATION, DROPLET MAKES NO WARRANTY OR GUARANTEE THAT THE SITE WILL BE
      UNINTERRUPTED, CORRECT, COMPLETE, TIMELY, SECURE, OR ERROR-FREE OR THAT
      DEFECTS IN THE SITE WILL BE CORRECTED.
    </p>
    <p>
      YOU UNDERSTAND AND AGREE THAT IF YOU OBTAIN MATERIALS, INFORMATION,
      PRODUCTS, OR SERVICES, YOU DO SO AT YOUR OWN DISCRETION AND RISK AND THAT
      YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES THAT MAY RESULT, INCLUDING
      LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM.
    </p>
    <p>
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF WARRANTIES, SO THE ABOVE
      EXCLUSIONS MAY NOT APPLY TO YOU.
    </p>
    <h2>Limitation of Liability</h2>
    <p>
      IN NO EVENT WILL DROPLET BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT,
      INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY TYPE
      WHATSOEVER RELATED TO OR ARISING FROM THE SITE OR ANY USE OF THE SITE, OR
      OF ANY SITE OR RESOURCE LINKED TO, REFERENCED, OR ACCESSED THROUGH THE
      SITE, OR FOR THE USE OR DOWNLOADING OF, OR ACCESS TO, ANY MATERIALS,
      INFORMATION, PRODUCTS, OR SERVICES, INCLUDING, WITHOUT LIMITATION, ANY
      LOST PROFITS, BUSINESS INTERRUPTION, LOST SAVINGS OR LOSS OF PROGRAMS OR
      OTHER DATA, EVEN IF DROPLET IS EXPRESSLY ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES. THIS EXCLUSION AND WAIVER OF LIABILITY APPLIES TO ALL CAUSES
      OF ACTION, WHETHER BASED ON CONTRACT, WARRANTY, TORT, OR ANY OTHER LEGAL
      THEORIES.
    </p>
    <h2>Disputes</h2>
    <p>
      You agree that any claim or controversy arising out of or relating to the
      Site or to any acts or omissions for which you may contend Droplet is
      liable, including but not limited to any claim or controversy as to
      arbitrability ("Dispute"), shall be finally and exclusively settled by
      arbitration. The arbitration shall be venued in Des Moines, Iowa, and
      shall apply the laws of the State of Iowa, excluding its conflicts of laws
      provisions. Arbitration will be conducted under the rules of the American
      Arbitration Association ("AAA"). The parties will jointly agree upon a
      neutral arbitrator. Any judgment upon the award rendered by the
      arbitrators may be entered in any court of competent jurisdiction. The
      agreement to arbitrate shall not be construed as an agreement to the
      joinder or consolidation of arbitration under this agreement with
      arbitration of disputes or claims of any non-party, regardless of the
      nature of the issues or disputes involved. To the fullest extent permitted
      by applicable law, no arbitration under these TOL shall be joined to an
      arbitration involving any other party subject to these TOL, whether
      through class arbitration proceedings or otherwise.
    </p>
    <p>
      THIS AGREEMENT PROVIDES THAT ALL DISPUTES BETWEEN YOU AND DROPLET WILL BE
      RESOLVED BY BINDING ARBITRATION. YOU THUS GIVE UP YOUR RIGHT TO GO TO
      COURT TO ASSERT OR DEFEND YOUR RIGHTS. YOU ALSO GIVE UP YOUR RIGHT TO
      PARTICIPATE IN OR BRING CLASS ACTIONS. YOUR RIGHTS WILL BE DETERMINED BY
      NEUTRAL ARBITRATORS AND NOT A JUDGE OR JURY. You are entitled to a fair
      hearing, and the arbitration procedures are simpler and more limited than
      rules applicable in court. Arbitrator decisions are enforceable as any
      court order and are subject to very limited review by a court. By using
      the Site, you consent to these restrictions.
    </p>
    <p>
      Should a Dispute arise and should the arbitration provisions herein become
      inapplicable or unenforceable, or in any instance of any lawsuit between
      you and Droplet, you agree that jurisdiction over and venue of any suit
      shall be exclusively in the state and federal courts sitting in Des
      Moines, Iowa. If either party employs attorneys to enforce any right in
      connection with any Dispute or lawsuit, the prevailing party shall be
      entitled to recover reasonable attorneys' fees.
    </p>
    <p>
      Use of the Site is unauthorized in any jurisdiction that does not give
      full effect to all provisions of this agreement, including without
      limitation this section and the warranty disclaimers and liability
      exclusions above. Droplet has endeavored to comply with all legal
      requirements known to it in creating and maintaining the Site, but makes
      no representation that materials on the Site are appropriate or available
      for use in any particular jurisdiction. Use of the Site is unauthorized in
      any jurisdiction where all or any portion of the Site may violate any
      legal requirements, and you agree not to access the Site in any such
      jurisdiction. Any use in contravention of this provision or any provision
      of this agreement is at your own risk and, if any part of this agreement
      is invalid or unenforceable under applicable law, the invalid or
      unenforceable provision will be deemed superseded by a valid, enforceable
      provision that most closely matches the intent of the original provision
      and the remainder of the agreement shall govern such use.
    </p>
    <p>
      <strong>Last revised: October 13, 2020.</strong>
    </p>
  </BasicPageContainer>
)

export default TermsOfUse
