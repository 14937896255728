import React from "react"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"

import TermsOfUse from "../../components/sections/legal/terms-of-use"
import Footer from "../../components/sections/footer"

const TermsOfUsePage = () => (
  <Layout>
    <SEO title="Terms of Use" />
    <Navigation />
    <TermsOfUse />
    <Footer />
  </Layout>
)

export default TermsOfUsePage
